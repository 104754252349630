@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 

    .footer{
        &__ctnr{
            margin-left: 8%;
            margin-right: 8%;
        }
    }
}

/* ----------- Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
    .footer{
        &__ctnr{
            margin-left: 8%;
            margin-right: 8%;
        }
    }
}

@media (max-width: 1266px) { 
    .footer{
        &__right-content{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &--position-initial{
                display: flex;
                align-items: flex-end;

                a{
                    text-align: right;
                }
                
                a:not(:last-of-type){
                        margin-bottom: 2.4rem;
                }

                p{
                    margin-top: 2.4rem;
                }
            }
    
            &--position-end{
                display: none;
            }
        }
    }
 }

 @media (max-width: 1068px) {
    .footer{
        &__ctnr{
            margin-left: 5.6rem;
            margin-right: 5.6rem;
        }
    }
 }

 @media (max-width: 991.98px) {

    .footer{
        &__left-content{
            width: 40%;
        }
        &__middle-content{
            width: 60%;

        }
        &__right-content{
            width: 40%;
        }
        &__social-media{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 3.2rem;
            margin-bottom: 3.2rem;
            
            img{
                width: 6rem;
                margin-left: 2.4rem;
                margin-right: 2.4rem;
                margin-top: 2.4rem;
                margin-bottom: 2.4rem;
            }
        }
    }
    
 }

 @media (max-width: 845px) {
    .footer{
        &__ctnr{
            margin-left: 8%;
            margin-right: 8%;
            flex-direction: column;
        }
        &__left-content{
            width: 100%;
            justify-content: center;
        }
        &__middle-content{
            width: 100%;
            .linksTxt{
                font-size: 2.2rem;
                margin-bottom: 0;
            }
        }
        &__right-content{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            
            a, p{
                margin-left: 1.4rem;
                margin-right: 1.4rem;
                margin-top: initial;
                text-align: center;
            }
            &--position-initial{
                display: none;
            }
    
            &--position-end{
                display: flex;
                margin-bottom: 6rem;
            }
        }
        &__social-media{
            margin-bottom: 2rem;

            img{
                width: 4.2rem;
            }
        }

        .paragraph--feature{
            margin-bottom: 5rem;
        }
    }
 }
 
 @media (max-width: 575.98px) {

    .footer{
        padding-left: 1rem;
        padding-right: 1rem;

        &__right-content{
            a, p{
                margin-top: 3.2rem;
                text-align: center;
                font-size: 2rem;
            }
        }
    }
    
 }