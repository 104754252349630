@media screen 
  and (min-width: 1200px) 
  and (max-width: 1600px) { 

    .navbar{
        &__ctnr{
            margin-left: 12.2%;
            margin-right: 12.2%;
        }
    }
}
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 

    .navbar{
        &__ctnr{
            margin-left: 8%;
            margin-right: 8%;
        }
    }
}
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 

    .navbar{
        &__ctnr{
            margin-left: 8%;
            margin-right: 8%;
        }
    }
}

/* ----------- Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
    .navbar{
        &__ctnr{
            margin-left: 8%;
            margin-right: 8%;
        }
    }
}

@media (max-width: 1266px) { 
    .navbar{
        &__ctnr{
            margin-left: 8%;
            margin-right: 8%;
        }
    }
 }
 @media (max-width: 1068px) {
    .navbar{
        &__ctnr{
            margin-left: 5%;
            margin-right: 5%;
        }
        &__links {
            display: flex;
            align-items: center;
            justify-content: flex-end;
    
            ul {
                :not(:last-child) {
                    margin-right: 2.4rem;
                }
            }
        }
    }
 }

@media (max-width: 845px) { 
    .navbar{
        &__ctnr{
            align-items: center;
            justify-content: center;
        }
        &__links{
            display: none;
        }
        &__logo-ctnr{
            svg {
                height: 2.4rem;
            }
        }

        &__hamburger{
            display: flex;
        }

        &__menu{
            .navbar__links{
                display: flex;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .navbar{
        &__ctnr{
            align-items: center;
            justify-content: center;
        }
        &__links{
            display: none;
        }
        &__logo-ctnr{
    
            img{
                height: 3.5rem;
            }
        }
    }
}