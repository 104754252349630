.btn{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BLACK;
    border: none;
    cursor: pointer;

    &--disabled{
        display: flex;
        padding: 0.8rem 1.6rem;
        border-radius: 1000px;
        font-size: 1.7rem;
        font-weight: 700;
        color: #969696;
        top: 0;
        left: 0;
        width: 500px;
        text-decoration: none;
        max-width: fit-content;
        background-color: #dadada;
        transition: all cubic-bezier(0.86, 0, 0.07, 1) 0.4s;
        position: relative;
        overflow: hidden;
        z-index: 100;
        cursor: not-allowed;

        .arrowRight{
            display: none;
        }
    }
    

    &--nav-link{
        height: 2.8rem;
        background: transparent;
        width: 14rem;
        border-radius: 1000px;
        transition: all ease-in-out 0.3s;
        color: $WHITE;
        font-size: 1.4rem;
        font-weight: 500;
        border-width: 2px;
        border-style: solid;
        border-color: $WHITE;
        transition: all cubic-bezier(0.86, 0, 0.07, 1) 0.4s;
        position: relative;

        &:hover{
            border-color: $WHITE;
            color: $BLACK;
            padding-right: 2rem;
            
            .arrowRight{
                right: 0;
                transform: translate(-1.5rem, -50%);
                opacity: 1;
            }

            &::before{
                left: 0;
                transform: translate(0, -50%);
            }
        }
        
        .arrowRight{
            color: $BLACK;
            margin-left: 0.4rem;
            width: 1.6rem;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            opacity: 0;
            transition: all cubic-bezier(0.86, 0, 0.07, 1) 0.4s;
        }

        &::before{
            content: "";
            display: flex;
            width: 100%;
            height: 100%;
            background-color: $WHITE;
            border: 2px solid $WHITE;
            box-sizing: content-box;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-100%, -50%);
            z-index: -1;
            border-radius: 1000px;
            transition: all cubic-bezier(0.86, 0, 0.07, 1) 0.4s;
        }
        &::after{
            content: "";
            display: flex;
            width: 100%;
            height: 100%;
            background-color: $BLACK;
            position: absolute;
            left: 50%;
            top: 50%;
            border: 2px solid $BLACK;
            box-sizing: content-box;
            transform: translate(-50%, -50%);
            z-index: -2;
            border-radius: 1000px;
        }

        &--over-white-bg {
            border-color: $BLACK;

            &:hover {
                border-color: $BLACK;
            }
        }
    }

    &--submit{
        padding: 1.6rem 2.4rem;
        border-radius: 1000px;
        font-size: 1.7rem;
        font-weight: 700;
        color: $WHITE;
        top: 0;
        left: 0;
        text-decoration: none;
        max-width: fit-content;

        img{
            margin-left: 0.8rem;
        }
    }

    &-mayan{
        display: flex;
        padding: 0.8rem 1.6rem;
        border-radius: 1000px;
        font-size: 1.7rem;
        font-weight: 700;
        color: $WHITE;
        top: 0;
        left: 0;
        width: 500px;
        text-decoration: none;
        max-width: fit-content;
        background-color: transparent;
        transition: all cubic-bezier(0.86, 0, 0.07, 1) 0.4s;
        position: relative;
        overflow: hidden;
        z-index: 100;
        
        .arrowRight{
            opacity: 0;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translateY(-50%);
            transition: all cubic-bezier(0.86, 0, 0.07, 1) 0.4s;
            height: 2.2rem;
        }

        p{
            padding-right: 1.6rem;
            padding-left: 1.6rem;
            // background-color: green;
            transition: all cubic-bezier(0.86, 0, 0.07, 1) 0.4s;
            font-weight: 500;
        }
        
        &::after{
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: -100%;
            top: 0;
            border-radius: 1000px;
            z-index: -1;
            transition: all cubic-bezier(0.86, 0, 0.07, 1) 0.4s;
        }
        &::before{
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 1000px;
            z-index: -2;
            transition: all cubic-bezier(0.86, 0, 0.07, 1) 0.4s;
        }

        &:hover{
            
            p{
                // background-color: blue;
                padding-right: 3.2rem;
                padding-left: 0rem;
            }

            .arrowRight{
                opacity: 1;
                right: 10%;
                transition: all cubic-bezier(0.86, 0, 0.07, 1) 0.4s;
            }

            &::after{
                left: 0;
            }
        }

        &--dark{
            .arrowRight{
                color: $BLACK;
            }

            &::before{
                background-color: $BLACK;
                border: 2rem solid $BLACK;
            }
            &::after{
                background-color: $WHITE;
                border: 0.2rem solid $BLACK;
            }

            &:hover{
                p{
                    // background-color: blue;
                    padding-right: 3.2rem;
                    padding-left: 0rem;
                    color: $BLACK;
                }
    
                .arrowRight{
                    color: $BLACK;
                }
            }
        }
        &--green{
            .arrowRight{
                color: $GREEN;
            }

            &::before{
                background-color: $GREEN;
                border: 2rem solid $GREEN;
            }
            &::after{
                background-color: $WHITE;
                border: 0.2rem solid $GREEN;
            }

            &:hover{
                p{
                    // background-color: blue;
                    padding-right: 3.2rem;
                    padding-left: 0rem;
                    color: $BLACK;
                }
    
                .arrowRight{
                    color: $GREEN;
                }
            }
        }
        // img{
        //     margin-left: 0.8rem;
        // }

    }
}

.link{
    text-decoration: none;
    color: $GREEN;
    font-size: 1.3rem;
    font-weight: 600;
}