@import "../../sass/global";
@import "../../sass/variables";
@import "../../sass/typography";
@import "../../sass/buttons";
@import "../../sass/animation";

.curtains {
    display: flex;
    position: fixed;
    z-index: 10001;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;

    &--active {
        left: 0;
    }

}

.curtain {
    display: flex;
    position: fixed;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;

    &--1 {
        background-color: $BLACK;
        z-index: 10002;
    }

    &--2 {
        z-index: 10002;
        background-color: $SMOKE;
    }

    &--active {
        animation-name: reveal;
        animation-direction: normal;
        animation-iteration-count: 1;
        animation-duration: 1000ms;
        animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
        animation-fill-mode: forwards;


        &:not(:first-of-type) {
            animation-delay: 0.15s;
        }
    }

}

.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    z-index: 1000;
    transition: all ease-in-out 0.4s;

    &__ctnr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 12.2%;
        margin-right: 12.2%;
        width: 100%;
        transition: all ease-in-out 0.4s;
    }

    &__logo-ctnr {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10.6rem;
        transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.4s;
        cursor: pointer;

        .SVGMayan-Letter {
            color: $WHITE;
        }

        .SVGMayan-Pyramid {
            color: $WHITE;
        }

        svg {
            height: 3.0rem;
            transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.4s;
        }

        &--shrinked {
            height: 4.5rem;
            transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.4s;

            .SVGMayan-Letter {
                color: $BLACK;
                animation-name: hideLetters;
                animation-direction: normal;
                animation-iteration-count: 1;
                animation-duration: 3s;
                animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
                animation-fill-mode: forwards;
            }

            .SVGMayan-Pyramid {
                color: $BLACK;
                animation-name: movepyramid;
                animation-direction: normal;
                animation-iteration-count: 1;
                animation-duration: 3s;
                animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
                animation-fill-mode: forwards;
            }

            svg {
                height: 2.0rem;
                transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.4s;
            }

            &:hover {
                .SVGMayan-Letter {
                    animation-name: showLetters;
                    animation-direction: normal;
                    animation-iteration-count: 1;
                    animation-duration: 3s;
                    animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
                    animation-fill-mode: forwards;
                }
                .SVGMayan-Pyramid {
                    // color: red;
                    animation-name: movepyramidR;
                    animation-direction: normal;
                    animation-iteration-count: 1;
                    animation-duration: 3s;
                    animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
                    animation-fill-mode: forwards;
                }
            }
        }
    }

    &__links {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        ul {
            li {
                display: inline-block;
                align-items: center;
                justify-content: center;
                flex-direction: row;

                a {
                    display: inline-block;
                    color: black;
                    text-decoration: none;
                    position: relative;
                    transform-origin: center;
                    transition: all ease-in-out 0.3s;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: -0.0182em;
                    cursor: pointer;

                    &:hover {
                        font-weight: 700;

                        &::after {
                            height: 0.4rem;
                            opacity: 1;
                            animation-name: test;
                            animation-direction: normal;
                            animation-iteration-count: 1;
                            animation-duration: 0.3s;
                            animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
                            animation-fill-mode: both;
                            transition: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s;
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 2rem;
                        background-color: $BLACK;
                        opacity: 0;
                        top: 50%;
                        left: 50%;
                        transition: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s;
                        transform: translate(-50%, -50%);
                        z-index: -10;

                        animation-name: test2;
                        animation-direction: normal;
                        animation-iteration-count: 1;
                        animation-duration: 0.3s;
                        animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
                        animation-fill-mode: both;
                    }

                }
            }

            :not(:last-child) {
                margin-right: 4.2rem;
            }
        }

        &--shrinked {
            ul {
                li {
                    a {
                        font-size: 1.2rem;

                        .btn--nav-link {
                            font-size: 1.2rem;
                        }
                    }
                }

                :not(:last-child) {
                    margin-right: 2.4rem;
                }
            }

            .btn--nav-link {
                border-color: $BLACK;

                &:hover {
                    border-color: $BLACK;
                }
            }
        }
    }

    &__hamburger{
        display: none;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        height: 10.6rem;
        margin-right: 5%;
        z-index: 1002;
        color: $WHITE;
        transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.4s;
        cursor: pointer;

        &:hover{
            opacity: 0.5;
        }

        &--shrinked {
            color: black;
            height: 4.5rem;
            width: 4.5rem;
            margin-right: 4%;
            transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.4s;
        }

        &--active{
            color: $WHITE !important;
            height: 10.6rem !important;
            margin-right: 5% !important;
            transform: rotate(180deg);
        }
    }

    &__menu{
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100%;
        background-color: $BLACK;
        z-index: 1001;
        transition: all cubic-bezier(0.77, 0, 0.175, 1) 0.4s;

        &--opened{
            top: 0;
            right: 0;
        }

        .navbar__links{
            justify-content: center;
            flex-direction: column;

            ul{
                li {
                    display: flex;
                    justify-content: center;
                    a {
                        font-size: 3.2rem;

                        .btn--nav-link {
                            width: 28rem;
                            height: 8rem;
                            font-size: 3.2rem;

                            .arrowRight{
                                width: 3.2rem;
                            }
                        }
                    }
                }
                :not(:last-child) {
                    margin-right: initial;
                    margin-bottom: 3rem;
                }
            }
        }
    }
}