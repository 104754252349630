@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .hero {

        &__bg {
            &--desktop {
                min-width: 100%;
                min-height: 100%;
            }
        }
    }

    .HIW {

        &__img-box {
            padding-top: 9rem;
            padding-bottom: 2.4rem;
        }
    }

}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .hero {

        &__bg {
            &--desktop {
                min-width: 100%;
                min-height: 100%;
            }
        }
    }

    .HIW {

        &__img-box {
            padding-top: 9rem;
            padding-bottom: 2.4rem;
        }
    }

    .features {
        padding-top: 12rem;
        padding-bottom: 12rem;

        &__txt-box {
            width: 82.0rem;
        }

        &__ctnr {
            min-height: 80rem;
        }

        &__app {
            min-width: 200px;
            max-width: 300px;
        }
    }

    .security {
        &__img-box {
            margin-right: 0%;
        }

        &__txt-box {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .paragraph {
                width: 60%;
            }
        }
    }

    .webapp {
        &__txt-box {

            .headerTitle {
                font-size: 6.5rem;
                line-height: 100%;
                margin-bottom: 4rem;
            }

        }
    }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    .hero {

        &__bg {
            &--desktop {
                min-width: 100%;
                min-height: 100%;
            }
        }
    }

    .HIW {

        &__img-box {
            padding-top: 9rem;
            padding-bottom: 2.4rem;
        }
    }

    .features {
        padding-top: 12rem;
        padding-bottom: 12rem;

        &__txt-box {
            width: 82.0rem;
        }

        &__ctnr {
            min-height: 80rem;
        }

        &__app {
            min-width: 200px;
            max-width: 300px;
        }
    }

    .security {
        &__img-box {
            margin-right: 0%;
        }

        &__txt-box {
            .paragraph {
                width: 60%;
            }
        }
    }

    .webapp {

        .headerTitle {
            width: 40rem;
        }

        &__txt-box {
            .headerTitle {
                font-size: 6.5rem;
                line-height: 100%;
                margin-bottom: 4rem;
            }

        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1266px) {
    .hero {
        &__txt-box {
            top: 40%;
            left: 8%;
        }

        &__bg {
            height: 100%;
            width: auto;
            object-fit: contain;
            right: -5%;
        }
    }

    .HIW {

        &__img-box {
            padding-top: 9rem;
            padding-bottom: 2.4rem;

            img {
                width: 60rem;
            }

            &:not(:last-of-type) {
                img {
                    width: 50rem;
                }
            }
        }

        &__txt-box {
            width: 60%;
        }

        &__imgs-ctnr {
            margin-top: 0;
        }
    }

    // .features {
    //     padding-top: 12rem;
    //     padding-bottom: 12rem;

    //     &__txt-box {
    //         width: 60%;
    //     }

    //     &__ctnr {
    //         min-height: 80rem;
    //     }

    //     &__app {
    //         min-width: 200px;
    //         max-width: 300px;
    //     }

    //     &__feature-box {

    //         &--1 {
    //             top: 45%;
    //             left: 12%;
    //         }

    //         &--2 {
    //             top: 20%;
    //             right: 12%;
    //         }

    //         &--3 {
    //             top: 65%;
    //             right: 12%;
    //         }
    //     }
    // }

    .security {
        &__img-box {
            margin-right: 0%;
        }

        &__txt-box {
            .paragraph {
                width: 60%;
            }
        }

        &__ctnr {
            width: 90%;
            min-width: initial;
        }
    }

    .webapp {

        &__txt-box {
            .headerTitle {
                font-size: 5.6rem;
                line-height: 100%;
                margin-bottom: 4rem;
            }

        }

        &__badges {

            &--badge10 {
                width: 25%;
            }

            &--badge50 {
                width: 25%;
            }

            &--badge100 {
                width: 25%;
            }

            &--badgeCard {
                width: 30%;
            }
        }
    }
}

@media (max-width: 1068px) {
    .hero {
        height: auto;
        min-height: initial;
        max-height: initial;

        &__txt-box {
            top: 40%;
            left: 5%;
        }

        background-color: black;

        &__bg {
            height: 100%;
            width: 100%;
            margin-top: 50px;
            position: relative;
            object-fit: contain;
            right: 0;
        }
    }

    .webapp {
        height: 80vh;
        max-height: 700px;
        min-height: 650px;

        &__txt-box {
            .headerTitle {
                font-size: 4.8rem;
                line-height: 100%;
                margin-bottom: 4rem;
            }
        }

        &__badges {

            &-badge-content {

                &--right-desktop {
                    padding: 7% 0 0 0;
                    font-size: 2vw;
                }

                &--left-desktop {
                    padding: 7% 0 0 0;
                    font-size: 2vw;

                    span {
                        font-size: 1.2vw;
                    }
                }
            }

            &--badge10 {
                width: 26.5%;
                display: none;
            }

            &--badge50 {
                width: 26.5%;
            }

            &--badge100 {
                width: 26.5%;
            }

            &--badgeCard {
                width: 32%;
            }
        }
    }

    .HIW {

        &__img-box {
            padding-top: 4.8rem;

            img {
                margin-top: 2rem;
                width: 60rem;
            }

            &:not(:last-of-type) {
                margin-bottom: 1.6rem;
                width: calc(50% - 0.8rem);

                img {
                    width: 43rem;
                }
            }
        }

        &__txt-box {
            min-width: 34rem;

            .headerTitle {
                font-size: 3.2rem;
            }

            .paragraph {
                font-size: 1.9rem;
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }

    }

    .features {

        &__txt-box {
            width: 80%;
        }

        height: 80vh;
        max-height: 1000px;
        min-height: 685px;
        padding-top: 8rem;

        &__app {
            width: 35rem;
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

    .HIW {

        &__img-box {
            padding-bottom: 0;
        }

        &__txt-box {
            min-width: 34rem;

            .headerTitle {
                font-size: 3.2rem;
            }

            .paragraph {
                font-size: 1.9rem;
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }

    }

    .security {
        padding-bottom: 4rem;

        &__img-box {
            margin-right: 0%;
        }

        &__txt-box {
            justify-content: center;
            align-items: center;
            margin-bottom: 5rem;

            .paragraph {
                width: 60%;
                text-align: center;
            }
        }

        &__ctnr {
            width: 90%;
            min-width: initial;
            flex-direction: column;
        }

        &__badges {
            &-badge {
                .linksTxt {
                    border-style: solid;
                    border-width: 0.3rem;
                    border-radius: 2.4rem;
                    padding: 0.8rem 2.4rem;
                    margin: 2.4rem 1.2rem 0 1.2rem;
                }
            }
        }
    }

    .earlyAccess {
        padding-top: 4rem;
        padding-bottom: 12.8rem;

        &__overflowImage {
            width: 80rem;
            position: absolute;
            top: 30%;
            right: initial;
            left: 62%;
            transform: translate(-28%, -33%);
        }

        &__ctnr {
            width: 90%;
        }

        &__data-box {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .paragraph {
                width: 80%;
            }
        }

        &__img-box {
            >img {
                max-width: 280px;
            }
        }
    }
}

@media (max-width: 845px) {
    .hero {
        height: auto;
        min-height: initial;
        max-height: initial;

        &__txt-box {
            top: 40%;
            left: 5%;
        }

        &__bg {
            height: 100%;
            width: 135%;
            margin-top: 0px;
            position: relative;
            object-fit: contain;
            right: 15%;
        }
    }

    .webapp {
        padding-top: 10rem;

        &__badges {

            &-ctnr {
                padding-left: 3%;
                padding-right: 3%;
            }

            &--badge10 {
                width: 30%;
                padding-left: 0%;
                padding-right: 0%;
                display: none;
            }

            &--badge50 {
                width: 30%;
                padding-left: 0%;
                padding-right: 0%;
            }

            &--badge100 {
                width: 30%;
                padding-left: 0%;
                padding-right: 0%;
            }

            &--badgeCard {
                width: 40%;
            }
        }
    }

    .HIW {
        padding: 1rem;

        &__img-box {
            padding-bottom: 0;

            &:not(:last-of-type) {
                margin-bottom: 1.6rem;
                width: calc(50% - 0.5rem);

                img {
                    width: 45rem;
                }
            }
        }
    }

    .features {

        &__ctnr {
            align-items: flex-start;
            margin-left: 20%;
        }

        &__feature-box {

            flex-direction: row;
            width: 26rem;

            &__txt {
                align-items: flex-start;
                width: calc(100% - 6.6rem);

                .u-txt-center {
                    text-align: left;
                }
            }

            &__icon {
                margin-bottom: 0;
                margin-right: 1.6rem;
                width: 5rem;
                height: 5rem;
            }

            &--1 {
                top: 10%;
                left: initial;
                right: -85%;
            }

            &--2 {
                top: 23%;
                right: -85%;
            }

            &--3 {
                top: 36%;
                right: -85%;
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

    .hero {
        max-height: 950px;

        &__txt-box {
            top: 10%;
            left: 50%;

            transform: translateX(-50%);
            width: 100%;
            justify-content: center;
            padding-left: 8rem;
            padding-right: 8rem;
        }

        &__bg {
            top: 0;
            right: 0;

            &--desktop {
                display: none;
            }

            &--mobile {
                display: flex;
            }

        }
    }

    .features {

        min-height: 560px;
        max-height: 705px;

        &__ctnr {
            margin-left: initial;
            margin-right: 80%;
            align-items: flex-end;
        }

        &__app {
            width: 39rem;
        }

        &__feature-box {

            flex-direction: row;
            width: 30rem;

            &__txt {
                align-items: flex-start;
                width: calc(100% - 10rem);

                .u-txt-center {
                    text-align: left;
                }

                .linksTxt {
                    font-size: 2rem;
                }

                .paragraph {
                    font-size: 1.7rem;
                }
            }

            &__icon {
                width: 6.5em;
                height: 6.5rem;

                >svg {
                    width: 60%;
                }
            }

            &--1 {
                right: -80%;
            }

            &--2 {
                right: -80%;
            }

            &--3 {
                right: -80%;
            }
        }


    }

    .earlyAccess {

        &__img-box {
            >img {
                max-width: 200px;
            }
        }
    }

    .webapp {
        height: 60vh;
        max-height: 760px;
        min-height: 540px;
    }

    .HIW {

        &__img-box {
            width: 100%;
            padding-bottom: 2.4rem;

            img {
                margin-top: 2rem;
                width: 62rem;
            }

            &:not(:last-of-type) {
                margin-bottom: 1.6rem;
                width: 100%;

                img {
                    width: 62rem;
                }
            }
        }

        &__txt-box {
            min-width: 40rem;

            .headerTitle {
                font-size: 4rem;
            }

            .paragraph {
                width: 50rem;
                font-size: 2.4rem;
            }
        }
    }

}

@media (min-width: 575.98px) and (max-width: 767.98px) and (max-height: 775px) {
    .hero {
        height: auto;
        min-height: initial;
        max-height: initial;
        background-color: red;

        &__txt-box {
            top: 30%;
            left: 6%;
            transform: initial;
            width: initial;
            padding-left: 0;
            padding-right: 0;
        }

        &__bg {
            height: 100%;
            width: 136%;
            margin-top: 0px;
            position: relative;
            object-fit: contain;
            right: 15%;

            &--desktop {
                display: flex;
                // width: 100%;
            }

            &--mobile {
                display: none;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .hero {
        height: 100vh;
        min-height: initial;
        max-height: 1000px;

        &__txt-box {
            justify-content: center;
            top: 10%;
            left: 50%;
            width: 100%;
            transform-origin: top center;
            transform: translate(-50%, 0);
            padding-left: 3rem;
            padding-right: 3rem;

            >.hugeTxt {
                text-align: center;
            }
        }

        &__bg {
            top: 0;
            right: initial;
            left: 0;
            transform: initial;
            object-fit: cover;

            &--desktop {
                display: none;
            }

            &--mobile {
                width: 100%;
                display: flex;
            }
        }
    }

    .features {
        height: 110vh;
        max-height: 1000px;
        min-height: 975px;

        &__ctnr {
            margin-right: initial;
            align-items: center;
        }

        &__app {
            width: 35rem;
        }

        &__feature-box {

            flex-direction: column;
            width: 30rem;

            &__txt {
                align-items: center;
                width: calc(100% - 10rem);

                .u-txt-center {
                    text-align: center;
                }
            }

            &__icon {
                margin-right: initial;
                margin-bottom: 1.6rem;
            }

            &--1 {
                right: 55%;
                top: initial;
                bottom: -25%;
                // transform: translate(-50%);
            }

            &--2 {
                right: initial;
                left: 55%;
                top: initial;
                bottom: -25%;
                // transform: translate(-50%);
            }

            &--3 {
                right: initial;
                left: 50%;
                top: initial;
                bottom: -45%;
                transform: translate(-50%);
            }
        }


    }

    .security {
        padding-bottom: 8rem;

        &__img-box {
            margin-right: 0%;

            img {
                width: 80%;
            }
        }

        &__txt-box {
            justify-content: center;
            align-items: center;
            margin-bottom: 5rem;

            .headerTitle {
                text-align: center;
            }

            .paragraph {
                width: 85%;
                text-align: center;
            }
        }
    }

    .earlyAccess {
        padding-top: 12.8rem;
        padding-bottom: 12.8rem;

        &__overflowImage {
            width: 80rem;
            position: absolute;
            top: 30%;
            right: initial;
            left: 62%;
            transform: translate(-28%, -33%);
        }

        &__ctnr {
            width: 90%;
            flex-direction: column-reverse;
        }

        &__data-box {
            margin-top: 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .headerTitle {
                text-align: center;
            }

            .paragraph {
                text-align: center;
                width: 80%;
            }
        }

        &__img-box {
            >img {
                max-width: 200px;
            }
        }


    }
}

@media (max-width: 440px) {
    .security {

        &__txt-box {

            .paragraph {
                margin-bottom: 2.4rem;
            }
        }

        &__badges {
            display: flex;
            flex-shrink: 0;
            flex-wrap: wrap;

            &-badge {
                display: flex;
                width: 100%;

                .linksTxt {
                    width: 100%;
                    font-size: 1.8rem;
                    text-align: center;
                    border-radius: 4rem;
                    padding: 1.5rem 2.4rem;
                    margin: initial;
                    margin-top: 1.6rem;
                }
            }
        }
    }

    .earlyAccess {
        &__form {
            width: 70%;
        }
    }

    .webapp {
        align-items: center;
        max-height: 550px;
        min-height: 480px;

        &__badges {

            &-badge-content {
                padding-left: 0;
                padding-right: 0;
            }

            &-ctnr {
                display: none;
            }

            &-grid {
                display: grid;
            }
        }

        .headerTitle {
            width: 100%;
            padding-left: 8rem;
            padding-right: 8rem;
            text-align: center;
        }

        &__txt-box {
            align-items: center;
            margin-left: initial;
            margin-right: initial;
        }

        .paragraph {
            text-align: center;
            padding-left: 8rem;
            padding-right: 8rem;
        }

        .alineador {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    .HIW {

        &__img-box {


            img {
                width: 48rem;
            }

            &:not(:last-of-type) {

                img {
                    width: 48rem;
                }
            }
        }

        &__txt-box {
            width: 85%;
            min-width: 40rem;

            .headerTitle {
                font-size: 3.4rem;
            }

            .paragraph {
                width: inherit;
                padding-left: 0;
                padding-right: 0;
                font-size: 2.1rem;

            }
        }
    }

    .features {
        height: 120vh;
        max-height: 1150px;
        min-height: 1150px;

        &__ctnr {
            margin-right: initial;
            align-items: center;
        }

        &__app {
            width: 35rem;
        }

        &__feature-box {

            flex-direction: column;
            width: 30rem;

            &__txt {
                align-items: center;
                width: calc(100% - 10rem);

                .u-txt-center {
                    text-align: center;
                }
            }

            &__icon {
                margin-right: initial;
                margin-bottom: 1.6rem;
            }

            &--1 {
                right: 40%;
            }

            &--2 {
                left: 40%;
            }

            &--3 {
                left: 50%;
                bottom: -55%;
            }
        }


    }
}

@media (max-width: 400px) {
    .hero {
        min-height: initial;
        max-height: initial;

        &__txt-box {
            top: 12%;
        }
    }

    .HIW {


        &__img-box {
            padding-bottom: 0;


            img {
                width: 55rem;
            }

            &:not(:last-of-type) {

                img {
                    width: 55rem;
                }
            }
        }
    }
}