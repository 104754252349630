@import "../../sass/global";
@import "../../sass/variables";
@import "../../sass/typography";

.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 90.0rem;
    max-height: 1080px;
    overflow: hidden;
    position: relative;

    &__txt-box {
        position: absolute;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        top: 50%;
        left: 11%;
        transform-origin: center;
        transform: translateY(-50%);
        mix-blend-mode: difference;
        color: white;
        transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.3s;
    }

    &__bg {
        position: absolute;
        top: 0;
        right: 0;
        object-fit: cover;

        &--desktop {
            display: flex;
            width: 100%;
        }

        &--mobile {
            display: none;
        }

    }

}

.HIW {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1.6rem;
    background-color: $WHITE;

    &__imgs-ctnr {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }


    &__img-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        flex-direction: column;
        padding-top: 9rem;
        padding-bottom: 2.4rem;
        overflow: hidden;
        transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.2s;

        img {
            margin-top: 2rem;
            width: 72rem;
        }

        &:not(:last-of-type) {
            margin-bottom: 1.6rem;
            width: calc(50% - 0.8rem);

            img {
                width: 50rem;
            }
        }
    }

    &__txt-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 45%;
        min-width: 40rem;

        .headerTitle {
            font-size: 4rem;
            text-align: center;
            line-height: 108.3%;
            letter-spacing: -0.0182em;
        }

        .paragraph {
            text-align: center;
            height: 5.6rem;
            width: 38rem;
        }
    }

    &__taglines-ctnr {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 50;
    }

    &__tagline-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: calc(60 / 690 * 100%);
        position: relative;
        width: 100%;
    }

    &__tagline {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 0.8rem;

        &--left {
            padding-left: 5%;
            padding-right: 12%;
        }

        &--right {
            padding-right: 5%;
            padding-left: 12%;
        }
    }

    &__description {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
    }
}

.features {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 12.8rem;
    width: 100%;
    background-color: $BLACK;
    height: 88vh;
    max-height: 900px;
    min-height: 820px;
    position: relative;
    overflow: hidden;

    &__txt-box {
        width: 82.0rem;
    }

    &__feature-box {
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 16.0rem;
        transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.6s;

        &__txt {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5rem;
            height: 5rem;
            margin-bottom: 1.6rem;
            color: $WHITE;
            background-color: $BLACK;
            border: 0.2rem solid $WHITE;
            border-radius: 4.25rem;
            transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.6s;
            position: relative;

            &::after {
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                border-radius: 8rem;
                background-color: $WHITE;
                transform: scale(0);
                transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.6s;
            }

            &--active {
                border: 0.2rem solid transparent;
                color: $BLACK;

                &::after {
                    content: "";
                    transform: scale(1);
                }
            }

            svg {
                z-index: 100;
                width: 2.8rem;
            }
        }

        &--1 {
            top: 22%;
            left: -52%;
        }

        &--2 {
            top: 3%;
            right: -52%;
        }

        &--3 {
            top: 36%;
            right: -52%;
        }
    }

    &__ctnr {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        margin-top: 6rem;
        position: relative;
        transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.6s;
    }

    &__app {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 38.5rem;
        position: relative;
        flex-direction: column;

        >img {
            position: absolute;
            width: 100%;
            opacity: 1;
            z-index: 100;
        }

        &-view {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: calc(100% - 0rem);
            flex: 0 0 100%;
            overflow: hidden;

            img {
                height: 100%;
                width: auto;
            }
        }
    }

    &__screen {
        display: flex;
        align-items: center;
        margin-top: 1.8rem;
        justify-content: center;
        padding-top: calc(700 / 375 * 100%);
        background-color: $WHITE;
        width: calc(100% - 5rem);
        border-radius: 3rem;
        position: relative;
        overflow: hidden;

        &-ctnr {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow-x: scroll;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            &::-webkit-scrollbar {
                display: none;
            }
        }

    }
}



.security {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: column;
    padding-top: 12.8rem;
    padding-bottom: 12.8rem;
    background-color: #00C087;

    &__ctnr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        min-width: 90rem;
    }

    &__txt-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .paragraph {
            width: 40%;
        }
    }

    &__img-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 8%;
        width: 31rem;
        right: 31rem;

        img {
            filter: 0px 186px 184px rgba(0, 0, 0, 0.0099487), 0px 93.0908px 92.0898px rgba(0, 0, 0, 0.0141168), 0px 56.0736px 55.4706px rgba(0, 0, 0, 0.0171174), 0px 35.9349px 35.5485px rgba(0, 0, 0, 0.0199356), 0px 23.2901px 23.0397px rgba(0, 0, 0, 0.0231814), 0px 14.6608px 14.5032px rgba(0, 0, 0, 0.0275577), 0px 8.42357px 8.33299px rgba(0, 0, 0, 0.0344421), 0px 3.70743px 3.66757px rgba(0, 0, 0, 0.05)
        }
    }

    &__badges {
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;

        &-badge {
            display: flex;

            .linksTxt {
                color: $WHITE;
                border-style: solid;
                border-width: 0.3rem;
                border-color: $BLACK;
                background-color: $BLACK;
                border-radius: 2.4rem;
                padding: 0.8rem 2.4rem;
                margin: 2.4rem 2.4rem 0 0;
            }
        }
    }
}

.earlyAccess {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 12.8rem;
    padding-bottom: 12.8rem;
    background-color: $SMOKE;
    position: relative;
    overflow: hidden;

    &__overflowImage {
        width: 86.7rem;
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
    }

    &__ctnr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        max-width: 1200px;
        z-index: 1;
    }

    &__data-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .headerTitle {
            max-width: 50rem;
        }

        .paragraph {
            width: 80%;
            max-width: 50rem;
        }
    }

    &__img-box {
        >img {
            max-width: 350px;
            z-index: 30;
        }
    }

    &__form {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        // background-color: beige;
        width: 80%;

        label {
            width: 100%;
            margin-top: 1.6rem;
            font-size: 1.8rem;
            font-weight: 600;
            color: $GREY;
            opacity: 1;
            transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s;
        }

        label:focus-within {
            color: $BLACK;
        }

        input[type=text],
        input[type=email] {
            background-color: transparent;
            border: none;
            border-bottom-style: solid;
            border-bottom-width: 0.2rem;
            border-bottom-color: $GREY;
            width: 100%;
            transform-origin: center;
            transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s;
            height: 3.8rem;
        }

        input[type=text]:focus,
        input[type=email]:focus {
            border: none;
            outline: none;
            box-shadow: none;
            border-bottom-style: solid;
            border-bottom-width: 0.2rem;
            border-bottom-color: $BLACK;
            transform-origin: center;
            transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s;
        }

        input[type=button],
        input[type=submit],
        button {
            margin-top: 2.4rem;
        }
    }

}

.webapp {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: $SMOKE;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    padding-top: 18rem;
    height: 90vh;
    max-height: 832px;
    min-height: 790px;

    .headerTitle {
        width: 48rem;
    }

    &__txt-box {
        margin-left: 10%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        z-index: 1000;

        .headerTitle {
            font-size: 5.6rem;
            line-height: 106.9%;
            margin-bottom: 4rem;
            // text-align: center;
        }

    }

    &__img-box {
        img {
            width: 100%;
        }
    }


    &__badges {
        display: flex;
        position: relative;
        height: auto;

        &-ctnr {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 100%;
            position: absolute;
            // background-color: #00c08655;
            left: 0;
            bottom: 0;
        }

        &-badge-content {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding-left: 10%;
            padding-right: 10%;

            &--column{
                flex-direction: column;
                padding-top: 55%;
            }

            &--right-desktop{
                display: flex;
                justify-content: center;
                align-items: baseline;
                padding: 7% 0 0 0;
                width: 40%;
                font-size: 1.6vw;
                text-align: center;
                font-weight: 700;
                color: white;
            }

            &--full-top{
                display: flex;
                justify-content: center;
                align-items: baseline;
                width: 100%;
                font-size: 4vw;
                text-align: center;
                font-weight: 700;

                span{
                    font-size: 1.8vw;
                }
            }
            &--full-bottom{
                display: flex;
                justify-content: center;
                align-items: baseline;
                width: 100%;
                font-size: 1.7vw;
                text-align: center;
                font-weight: 700;
                color: #808080;
            }

            &--left-desktop{
                display: flex;
                justify-content: center;
                align-items: baseline;
                padding: 7% 0 0 0;
                width: 60%;
                font-size: 1.6vw;
                text-align: center;
                font-weight: 700;

                span{
                    font-size: 1vw;
                }
            }
            
            &--right{
                display: flex;
                justify-content: center;
                align-items: baseline;
                padding: 6.5% 1.6rem 0 1.6rem;
                width: 40%;
                font-size: 4.5vw;
                font-weight: 700;
                color: white;
            }
            &--left{
                display: flex;
                justify-content: center;
                align-items: baseline;
                padding: 6.5% 1.6rem 0 1.6rem;
                width: 60%;
                font-size: 4.5vw;
                font-weight: 700;

                span{
                    font-size: 2.5vw;
                }
            }
        }

        &-grid {
            display: none;
            width: 100%;
            padding-left: 2.4rem;
            padding-right: 2.4rem;
            grid-template-columns: calc(50% - 1.2rem) calc(50% - 1.2rem);
            grid-gap: 2.4rem;
        }

        &--mobile{
            display: flex;
            width: 100%;
            position: relative;
            // background-color: #00C087;
        }

        &--badge10 {
            display: flex;
            align-items: center;
            // background-color: #0fb1535b;
            padding-left: 0.5%;
            padding-right: 0.5%;
            width: 20%;
        }

        &--badge50 {
            display: flex;
            align-items: center;
            // background-color: #998d3340;
            padding-left: 0.5%;
            padding-right: 0.5%;
            width: 20%;
        }

        &--badge100 {
            display: flex;
            align-items: center;
            // background-color: #99523340;
            padding-left: 0.5%;
            padding-right: 0.5%;
            width: 20%;
        }

        &--badgeCard {
            display: flex;
            align-items: center;
            // background-color: #99338b3c;
            width: 25%;
        }
    }
}