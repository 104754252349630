@import "../../sass/global";
@import "../../sass/variables";
@import "../../sass/typography";
@import "../../sass/buttons";
@import "../../sass/animation";

.footer{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: $BLACK;
    width: 100%;
    // overflow: hidden;

    &__ctnr{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 14.6rem;
        margin-right: 14.6rem;
        width: 100%;
    }
    &__left-content,
    &__middle-content,
    &__right-content {
        .linksTxt {
            font-weight: 400;
            color: $WHITE;
        }
    }

    &__left-content{
        display: flex;
        width: 33.33%;
        align-items: center;
        justify-content: flex-start;
        height: 10.6rem;
        filter: invert(100%);

        img{
            height: 3.0rem;
        }
    }

    &__middle-content{
        width: 33.33%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    &__social-media{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3.2rem;
        margin-bottom: 3.2rem;

        a{
            transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.3s;
            &:visited {
                color: #808080;
            }

            &:link {
                color: #808080;
            }

            &:active {
                color: #808080;
            }

            &:hover {
                color: $WHITE;
            }
        }
        
        svg{
            width: 3.6rem;
            margin-left: 1.6rem;
            margin-right: 1.6rem;
        }
    }
    
    &__right-content{
        width: 33.33%;
        align-items: center;
        justify-content: flex-end;
        
        a{
            margin-left: 3.2rem;
            // text-decoration: none;
            text-align: center;
        }

        p{ 
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            margin-left: 3.2rem;

            svg{
                transition: all cubic-bezier(1, 0, 0, 1) 0.3s;  
            }
            
            
            &.active{
                svg{
                    transform: rotate(180deg);   
                }
            }

        }

        &--position-initial{
            display: flex;
        }

        &--position-end{
            display: none;
        }
    }

    &__language{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #1C1C1E;
        overflow: hidden;
        z-index: 5000;
        opacity: 0;
        transform: scaleY(0);
        transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.4s;

        &-option{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            padding-left: 2.4rem;
            padding-right: 1.6rem;
            padding-top: 1rem;
            padding-bottom: 1rem;

            &:hover{
                background-color: #525252;
            }

            &--active{
                position: relative;
                background-color: #525252;

                &::after{
                    content: "";
                    display: flex;
                    position: absolute;
                    left: 0;
                    width: 0.5rem;
                    height: 100%;
                    background-color: rgba(255, 255, 255, 0.5);
                    z-index: 100;
                }
            }

            .paragraph{
                margin-top: 0;
                text-align: right;

                img{
                    width: 2.4rem;
                    margin-right: 0.8rem;
                }
            }
        }

        &--active {
            animation-name: showLanguageModal;
            animation-direction: normal;
            animation-iteration-count: 1;
            animation-duration: 0.4s;
            animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
            animation-fill-mode: forwards;
        }
        &--inactive {
            animation-name: hideLanguageModal;
            animation-direction: normal;
            animation-iteration-count: 1;
            animation-duration: 0.4s;
            animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
            animation-fill-mode: forwards;
        }
    }
}