@keyframes hideLetters {
    70% {
      transform:translateX(0);
      color: currentColor;
    }
    85% {
      color: transparent;
    }
    100% {
      transform:translateX(-50%);
      color: transparent;
    }
  }
@keyframes showLetters {
    0% {
        transform:translateX(-50%);
        color: transparent;
      }
    5% {
        color: transparent;
    }
    20% {
      transform:translateX(0);
      color: black;
    }
    100% {
        transform:translateX(0);
        color: black;
    }
  }
  @keyframes test {
    0%{
      opacity: 0;
      height: 2rem;
    }
    15%{
      height: 2rem;
      opacity: 1;
    }
    50% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(-50%, 1.6rem);
      height: 0.4rem;
    }
  }

  @keyframes reveal {
    0%{
      opacity: 1;
      left: 100%;
    }
    50%{
      opacity: 1;
      left: 0;
      width: 100%;
    }
    100% {
      opacity: 1;
      left: 0;
      width: 0%;
    }
  }

  @keyframes test2 {
    0%{
      opacity: 1;
      transform: translate(-50%, 1.6rem);
      height: 0.4rem;
    }
    50% {
      transform: translate(-50%, -50%);
      height: 2rem;
    }
    85% {
      opacity: 1;
    }
    100% {
      height: 2rem;
      opacity: 0;
    }
  }

  @keyframes showLanguageModal {
    0%{
      transform: scaleY(0);
      opacity: 0;
      bottom: 0;
    }
    15% {
      transform: scaleY(1);
      opacity: 0;
    }
    80%{
      opacity: 1;
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(1);
      bottom: calc(100% + 2rem);
      opacity: 1;
    }
  }

  @keyframes hideLanguageModal {
    0%{
      transform: scaleY(1);
      bottom: calc(100% + 2rem);
      opacity: 1;
    }
    15% {
      transform: scaleY(1);
      opacity: 1;
    }
    80%{
      opacity: 0;
      bottom: 0;
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
      opacity: 0;
    }
  }

  @media (max-width: 845px) {
    @keyframes movepyramid {
      85% {
        transform:translateX(0);
      }
      100% {
        transform:translateX(36%);
        // color: transparent;
      }
    }
    @keyframes movepyramidR {
      0%{
        transform:translateX(36%);
      }
      15% {
        transform:translateX(0);
      }
      100% {
        transform:translateX(0);
        // color: transparent;
      }
    }
  }