@import "../../sass/global";
@import "../../sass/variables";
@import "../../sass/typography";


.mayan-card{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: $GRAY;
    flex-direction: column;
    width: 100%;
    max-width: 460px;
    border-radius: 0.4rem;
    padding: 4.2rem 5.2rem;
    transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s;

    &-title{
        font-size: 2.4rem;
        margin-bottom: 2.2rem;
        transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s;
    }

    &-content{
        .paragraph{
            font-size: 2rem;
            font-weight: 500;
            transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s;
        }
    }

    &:not(.mayan-card--superminimal):hover {

        background-color: $BLACK;

        .mayan-card {
            &-title {
                color: $WHITE;
            }

            &-content {
                .paragraph {
                    color: $WHITE;
                }
            }
        }
    }

    &--superminimal{
        background-color: transparent;
        padding: 0;
        max-width: 30rem;
        margin-bottom: 2.4rem;
        width: calc(50% - 1.6rem);

        .mayan-card{
            &-title{
                margin-bottom: 0.2rem;
            }
        }

        &-content{
            .paragraph{
                font-weight: 400;
            }
        }
    }

}

.aboutUs{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    // background-color: gray;
    padding-top: 8rem;
    padding-bottom: 12.8rem;
    padding-left: 12.2%;
    padding-right: 12.2%;

    &__ctnr {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        max-width: 192rem;
        
    }

    &__title-box{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-top: 18rem;
        letter-spacing: -1.82%;

        .headerTitle{
            font-weight: 700;
            font-size: 3.6rem;
            width: 100%;
            max-width: 82rem;
            line-height: 108.3%;
        }
    }

    &__values{

        &-cards-ctnr{
            display: flex;
            width: 100%;
            margin-top: 9rem;
            align-items:stretch;
            justify-content: space-between;
            max-height: 30rem;
            
            :not(:last-of-type){
                margin-right: 1.6rem;
            }
        }

        &-description{
            display: flex;
            margin-top: 9rem;
            width: 100%;
            flex-direction: column;

            .headerTitle{
                font-size: 4.0rem;
                font-weight: 700;
                margin-bottom: 2.4rem;
            }

            .paragraph{
                font-weight: 500;
                font-size: 2.1rem;
                max-width: 80rem;
            }
        }
        &-values-ctnr{
            display: flex;
            justify-content: space-between;
            max-width: 68rem;
            flex-wrap: wrap;

            :not(:first-of-type){
                margin-right: 1.6rem;
            }

        }
    }
    &__people{

        &-ctnr{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 4.2rem;
            flex-wrap: wrap;
        }
        &-card{
            display: flex;
            align-items: center;
            width: calc(50% - 3.2rem);
            margin-bottom: 4.2rem;
        }
        &-img-box{
            min-width: 14rem;
            width: 14rem;

            img{
                width: 100%;
            }
        }
        &-txt-box{
            margin-left: 2.4rem;

            .headerTitle{
                font-size: 2.4rem;
            }

            .paragraph{
                font-size: 2rem;
                font-weight: 400;
            }
        }
    }
}