@media screen and (min-width: 1200px) and (max-width: 1600px) {}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {}

// Large devices (desktops, less than 1200px)
@media (max-width: 1266px) {}

@media (max-width: 1068px) {}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {}

@media (max-width: 845px) {}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {}

@media (min-width: 575.98px) and (max-width: 767.98px) and (max-height: 775px) {}

@media (max-width: 575.98px) {}

@media (max-width: 440px) {
    .FAQ {
        &__ctnr {
            width: 85%;
            min-width: 20rem;
        }
    }

}

@media (max-width: 400px) {}