@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .aboutUs{
        padding-left: 8%;
        padding-right: 8%;
    }

}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {}

// Large devices (desktops, less than 1200px)
@media (max-width: 1266px) {
    .aboutUs{
        padding-left: 8%;
        padding-right: 8%;

        &__title-box{
            margin-top: 10rem;
        }

        &__people{


            &-txt-box{
                .headerTitle{
                    font-size: 2.2rem;
                }
                .paragraph{
                    font-size: 1.7rem;
                }
            }

            &-card{
                width: calc(50% - 2.4rem);
            }

            &-img-box{
                min-width: 11rem;
            }
        }
    }

    .mayan-card{
        padding: 2.4rem 3.2rem;
        &-title{
            font-size: 2.3rem;
            margin-bottom: 1.6rem;
        }

        &-content{
            .paragraph{
                font-size: 1.8rem
            }
        }
    }

}

@media (max-width: 1068px) {

    .aboutUs{

        &__people{

            &-ctnr{
                flex-direction: column;
            }
            &-card{
                width: 100%;
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .mayan-card{
        border-radius: 0.8rem;
        max-width: initial;
    }

    .aboutUs{
        &__values{
            &-cards-ctnr{
                flex-direction: column;
                margin-bottom: 8rem;

                :not(:last-of-type){
                    margin-right: 0;
                    margin-bottom: 1.6rem;
                }
            }
        }
    }
}

@media (max-width: 845px) {}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {}

@media (min-width: 575.98px) and (max-width: 767.98px) and (max-height: 775px) {}

@media (max-width: 575.98px) {
    .aboutUs{

        &__values{

            &-values-ctnr{
                flex-direction: column;
                max-width: initial;
                width: 100%;

                :not(:first-of-type){
                    margin-right: 0;
                }
            }
        }
    }

    .mayan-card{

        &--superminimal{
            width: 100%;
            padding: 1.6rem 0rem;
            margin-bottom: 1.6rem;
        }
    }
}

@media (max-width: 440px) {}

@media (max-width: 400px) {}