@import "../../sass/global";
@import "../../sass/variables";
@import "../../sass/typography";

.mayan-modal {
  display: flex;
  width: 100%;
  max-width: 43.4rem;
  min-width: 20rem;
  position: relative;
  align-items: flex-start;
  flex-direction: column;

  .close-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.2rem;
    height: 4.2rem;
    position: absolute;
    right: 10%;
    top: 10%;
    border: none;
    background: none;
    outline: none;
    color: $WHITE;
    opacity: 0.4;

    &:hover{
      opacity: 1;
    }
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 80%;
    background-color: $GREEN;
    height: 40%;
    bottom: -5%;
    opacity: 0;
    transform: translateY(50%);
    border-radius: 2.4rem;
    filter: blur(49px);
    animation: modalAppear 0.6s forwards;
    animation-delay: 0.3s;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -2;
    width: 100%;
    background: rgba(154, 192, 0, 0.36);
    height: 60%;
    bottom: 5%;
    border-radius: 2.4rem;
    opacity: 0;
    transform: translateY(50%);
    filter: blur(49px);
    animation: modalAppear 0.6s forwards;
    animation-delay: 0.3s;
  }

  &-full-screen-ctnr {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 50000;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all ease-in;
    animation: backgroundAppear 0.6s forwards;
    overflow: hidden;
  }

  &-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: black;
    border-radius: 2.8rem;
    padding: 1rem;
    // padding-bottom: 2.4rem;
    opacity: 0;
    transform: translateY(50%);
    position: relative;
    animation: modalAppear 0.6s forwards;
    animation-delay: 0.3s;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 4.2rem;
    padding-bottom: 4.2rem;
    font-size: 3.2rem;
    font-weight: 600;
    color: white;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-actions {
    display: flex;
    flex-direction: column;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    align-items: center;
  }
}

@keyframes backgroundAppear {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 50%;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes modalAppear {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  50% {
    opacity: 50%;
    transform: translateY(-10%);
  }

  100% {
    opacity: 100%;
    transform: translateY(0);
  }
}

