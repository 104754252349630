@import "../../sass/global";
@import "../../sass/variables";
@import "../../sass/typography";


.mayan-form {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  // background-color: red;
  width: 90%;

  &--black {
    .mayan-label {
      color: rgba(255, 255, 255, 0.4);
    }

    .mayan-label:focus-within {
      color: $WHITE;
    }

    .mayan-input {
      border-bottom-color: rgba(255, 255, 255, 0.4);
      ;

      &:focus {
        border-bottom-color: $WHITE;
      }
    }
  }
}

.mayan-label {
  width: 100%;
  margin-top: 1.6rem;
  margin-bottom: 3.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: $GREY;
  opacity: 1;
  transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s;
  position: relative;

  &--error {
    color: $REDLIGHT !important;
  }
}

.errorMesssage{
  display: flex;
  width: 100%;
  color: $REDLIGHT;
  top: 110%;
  z-index: 10000;
  position: absolute;
  font-size: 1.3rem;
  font-weight: 600;
  // padding-top: 1rem;
  // padding-bottom: 1rem;

  &--response{
    top: initial;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 100%;
    background-color: rgba(255, 79, 56, 0.15);
    padding: 1.6rem;
    margin-top: 1.6rem;
  }
}

.mayan-label:focus-within {
  color: $BLACK;
}

.mayan-input {
  background-color: transparent;
  border: none;
  border-bottom-style: solid;
  border-bottom-width: 0.2rem;
  border-bottom-color: $GREY;
  width: 100%;
  transform-origin: center;
  transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s;
  height: 3.8rem;

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
    border-bottom-style: solid;
    border-bottom-width: 0.2rem;
    border-bottom-color: $BLACK;
    transform-origin: center;
    transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s;
  }

  &--dark{
  color: white;
  }

  &--error {
    border-bottom-color: $REDLIGHT !important;
  }
}