*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat' !important;
  list-style: none;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
@media (max-width: 767.98px) {
  html {
    font-size: 48%;
  }  
}