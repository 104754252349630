@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

* {
    font-family: "Montserrat", sans-serif
}

.u-txt-center {
    text-align: center;
}

.u-txt-white {
    color: $WHITE !important;
}

.u-txt-copyright {
    font-size: 1.2rem !important;
    color: $WHITE !important;
}

// body,
// p,
// span {
//     font-weight: 400;
//     line-height: 1.7;
//     color: $BLACK;
//     font-size: 1.9rem;
// }

.hugeTxt {
    font-size: 5.6rem;
    position: relative;
    line-height: 108.3%;
    letter-spacing: -0.0182em;
    transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.3s;

    &--dot {
        margin-right: 4rem;

        &::after {
            content: url(../assets/svgs/dot.svg);
            position: absolute;
            transform-origin: center;
            transform: translateY(-10%);
            margin-left: 1rem;
            transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.3s;
        }
    }
}

.headerTitle {
    font-size: 5.6rem;
    font-weight: 400;
    transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.3s;

    b {
        font-weight: 700;
    }
}

.paragraph {
    font-size: 2rem;
    font-weight: 500;

    &--feature {
        font-weight: 400;
        font-size: 1.5rem;
    }
}

.linksTxt {
    font-size: 1.7rem;
    font-weight: 700;
    color: $BLACK;

    .btn {
        font-size: 1.7rem;
        font-weight: 700;
        color: $WHITE;
    }

    &--active{
        font-weight: 700 !important;

        &::after {
            height: 0.4rem !important;
            opacity: 1 !important;
            transform: translate(-50%, 1.6rem) !important;
        }
    }
    
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .hugeTxt {
        font-size: 5.6rem;
        position: relative;
    }

    .headerTitle {
        font-size: 5rem;
        font-weight: 400;

        b {
            font-weight: 700;
        }
    }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .hugeTxt {
        font-size: 5.6rem;
        position: relative;
    }

    .headerTitle {
        font-size: 5rem;
        font-weight: 400;

        b {
            font-weight: 700;
        }
    }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    .hugeTxt {
        font-size: 5.6rem;
        position: relative;
    }

    .headerTitle {
        font-size: 5rem;
        font-weight: 400;

        b {
            font-weight: 700;
        }
    }
}

@media (max-width: 1266px) { 
    .hugeTxt {
        font-size: 4.8rem;

        &--dot {
            margin-right: 4rem;
    
            &::after {
                transform: scale(0.8);
            }
        }
    }
    .headerTitle {
        font-size: 4.8rem;
        font-weight: 400;

        b {
            font-weight: 700;
        }
    }
 }

 @media (max-width: 991.98px){

    .headerTitle {
        font-size: 4.2rem;
    }

    .hugeTxt {
        font-size: 4.2rem;
        line-height: 130%;

        &--dot {
            margin-right: 4rem;
    
            &::after {
                transform: scale(0.7);
            }
        }
    }
 }

 @media (max-width: 845px) { 
    .hugeTxt{
        line-height: 105%;
        font-size: 4.8rem;

        &--dot {
            margin-right: 0;
    
            &::after {
                display: none;
                transform: scale(0.7);
            }

            // &:not(:last-of-type){
            //     color: red;
            // }
        }

        span{
            display: block;
        }

        br{
            display: none;
        }

        span:last-child{
            display: none;
        }
    }
}

 @media (max-width: 767.98px) {
    .hugeTxt{
        line-height: 105%;
        font-size: 6rem;
        text-align: center;

        &--dot {
            margin-right: 4.8rem;
    
            &::after {
                display: inline-flex;
                position: absolute;
                transform: scale(0.7);
                margin-left: 1rem;
            }
        }

        span{
            display: initial;
        }

        br{
            display: initial;
        }

        span:last-child{
            display: initial;
        }
    }
    .u-txt-copyright {
        font-size: 1.5rem !important;
    }
 }

 @media (min-width: 575.98px) and (max-width: 767.98px) and (max-height: 775px) { 
    .hugeTxt{
        line-height: 105%;
        font-size: 5.2rem;
        text-align: initial;

        &--dot {
            margin-right: 0;
    
            &::after {
                display: none;
                transform: scale(0.7);
            }
        }

        span{
            display: block;
        }

        br{
            display: none;
        }

        span:last-child{
            display: none;
        }
    }
}

 @media (max-width: 575.98px) {
    .paragraph {
        &--feature {
            font-weight: 400;
            font-size: 2rem;
        }
    }
    .hugeTxt {
        font-size: 4.6rem;

        &--dot {
           margin-right: 4rem;
   
           &::after {
               transform: translateY(-1.5%) scale(0.5);
               margin-left: 0.6rem;
           }
       }
    }
 }
 @media (max-width: 400px) {
 .hugeTxt {
     font-size: 3.8rem;

     &--dot {
        margin-right: 4rem;

        &::after {
            transform: translateY(-1.5%) scale(0.5);
            margin-left: 0.6rem;
        }
    }
 }
}
@media (max-width: 340px) {
    .hugeTxt {
        font-size: 2.8rem;
   
        &--dot {
           margin-right: 4rem;
   
           &::after {
               transform: translateY(-2%) scale(0.65);
               margin-left: 0.6rem;
           }
       }
    }
}