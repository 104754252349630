@import "../../sass/global";
@import "../../sass/variables";
@import "../../sass/typography";

.FAQ {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding-top: 8rem;
    padding-bottom: 12.8rem;
    min-height: 80vh;

    &__txt-box {
        margin-top: 8rem;
        margin-bottom: 7.2rem;
    }

    &__ctnr {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 48rem;
        width: 100%;
    }

    &__question-ctnr {
        display: flex;
        width: 100%;
        margin-top: 1.6rem;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s;
    }
    
    &__question {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        border-radius: 4rem;
        transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s;
        background-color: $GRAY;

        .linksTxt{
            font-size: 1.7rem;
            font-weight: 500;
            transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s;
        }
        
        &--active{
            background-color: $BLACK; 

            .linksTxt{
                color: white;
            }
        }

        &:hover{
            cursor: pointer;
        }
    }
    
    &__answer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-left: 3.2rem;
        padding-right: 3.2rem;
        overflow: hidden;
        max-height: 1000px;
        position: relative;
        transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 1s;
        
        .paragraph{
            margin-top: 1.6rem;
            margin-bottom: 1.6rem;
            font-size: 1.7rem;
            font-weight: 400;
            transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s;
            transform: translateY(0);
        }
        
        &[aria-expanded="true"]{
            max-height: 0px;
            transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s;
            
            .paragraph{
                transform: translateY(-100px);
            }
        }
        &[aria-expanded="true"]{
            max-height: 0px;
            transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s;
            
            .paragraph{
                transform: translateY(-100px);
            }
        }
    }
}
